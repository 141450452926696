<template>
  <router-view></router-view>
  <cookie></cookie>
  <ModalAvisos></ModalAvisos>
</template>

<script>
import Cookie from "./components/Cookies";
import ModalAvisos from "./components/ModalAvisos.vue";

export default {
  name: "App",
  components: { Cookie, ModalAvisos }
}
</script>

<style scoped></style>