<template>
  <div v-if="show" class="full-screen">

    <div class="avisoModal">
      <div class="avisotitle">
        <div class="row avisotitlerow">
          <div class="col-md-1 close" @click="desativar_modal"> &times; </div>
        </div>
      </div>
      <video :src="checkDeviceAndOrientation() ? video.mobile : video.desktop" loop="true" playsinline  autoplay="autoplay"
        muted></video>
    </div>

  </div>
</template>

<script>
export default {
  name: "ModalAvisos",
  data() {
    return {
      show: false,
      video: { 'mobile': "/assets/videos/final_ano_tkx_2024_mobile.mp4", 'desktop': '/assets/videos/final_ano_tkx_2024.mp4' },
      isMobile: false,
      isVertical: false
    };
  },
  methods: {
    checkDeviceAndOrientation() {
      // Check if the device is mobile using user agent
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
      this.isMobile = mobileRegex.test(userAgent);

      // Check if the screen orientation is vertical
      this.isVertical = window.innerWidth < window.innerHeight;
      return this.isVertical;
    },
    desativar_modal() {
      window.localStorage.setItem('modalavisos', Math.floor(new Date().getTime() / 1000));
      this.show = false;
    }
  },
  mounted() {
    let data = window.localStorage.getItem('modalavisos')
    let time = data - Math.floor(new Date().getTime() / 1000);
    if (data == null || time > 60 * 60 * 24) {
      window.localStorage.removeItem('modalavisos');
      this.show = true;
    }
    console.log(this.checkDeviceAndOrientation());
  },
};
</script>

<style>
.full-screen {
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  background: #000000;
  z-index: 9999999999;
  display: flex;
  justify-content: center;
}

.logoAviso {
  width: fit-content;
}

.avisotitle {
  position: absolute;
  right: 40px;
}

.close {
  font-size: 35pt;
  text-align: end;
  padding-top: 3px;
  color: #d02224;

  z-index: 99999;
  opacity: 1;
}

.titleAviso {
  text-align: center;
  padding-top: 16px;
}

.avisoModal {
  position: absolute;
  opacity: 1;
  z-index: 99999;
  overflow: hidden;
  background: #000000;
  display: flex;
  justify-content: center;
}

video {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
}

.avisoFooter {
  padding-right: 15px;
  padding-left: 15px;
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 14pt;
}


/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .avisoModal {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .avisotitlerow {
    display: block;
    right: -25px;
    position: absolute;

  }

  .avisotitle {
    font-size: 16px;
    padding: 10px;
  }

  .titleAviso {
    font-size: 14px;
    padding-top: 10px;
  }

  .close {
    font-size: 30pt;
    padding-top: 5px;
    z-index: 99999;
  }

  .avisoFooter {
    font-size: 12pt;
    padding: 10px;

    padding-left: 40px;
  }

  .logoAviso img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }

  video {
    width: 100%;
    height: auto;
  }
}
</style>
